
import {Component, Mixins, Ref} from "vue-property-decorator";
import GenelIslemFormWizard, {GenelIslemEntity} from "@/components/forms/form-wizard/eicrapro/GenelIslemFormWizard.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IExcelDosya, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import {VForm} from "@/types";
import {MtsOdemeTaskDataInterface} from "@/plugins/uyap-plugin/store/modules/TaskDataInterface/MtsOdemeTaskDataInterface";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {IAvukatDosya} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

@Component({
  components: {PersonelPicker, GenelIslemFormWizard}
})
export default class MtsOdemeFormWizard extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  @Ref('genel-form')  genelForm!: GenelIslemFormWizard;

  localValue: GenelIslemEntity = new GenelIslemEntity();
  onFly=false;
  valid = false;

  stepperHeaders = [
    "Listelenecek Takipler",
    "Takipleri Seç",
    "MTS Ödeme Yap",
    "İşlemler Sıraya Alındı"
  ];

  islemDegerleri: MtsOdemeTaskDataInterface = {
    kotaTur: 0,
    icraTakipSebep:{
      isim: "Tebliğ İmkansızlığı",
      id: 0,
      kod: "TEBLIG_IMKANSIZLIGI",
      kapatmaTuru: 1
    }
  }

  kotaKullanimSekli = [
    {isim: "Avukat", id: 0},
    {isim: "Kurum", id: 1}
  ]

  mtsIcraSebepList = [
    {
      isim: "Tebliğ İmkansızlığı",
      id: 0,
      kod: "TEBLIG_IMKANSIZLIGI",
      kapatmaTuru: 1
    },
    {
      isim: "Borcun Tamamının Ödenmemesi",
      id: 1,
      kod: "SURESINDE_TAM_ODEME_YAPMAMA",
      kapatmaTuru: 1
    },
    {
      isim: "Borcun Kısmen Ödenmesi",
      id: 2,
      kod: "SURESINDE_KISMEN_ODEME_YAPMA",
      kapatmaTuru: 1
    },
    {
      isim: "Yetkiye İtirazının Kaldırılması",
      id: 9,
      kod: "YETKIYE_ITIRAZIN_KALDIRILMASI",
      kapatmaTuru: 1
    }
  ];

  async createTask() {
    if (this.form.validate()) {
      this.onFly=true;
      let tasks: Array<Task> = [];
      //@ts-ignore
      this.localValue.secilen_takipler.forEach((secilenTakip: IAvukatDosya | IExcelDosya) => {
        let task: Task = new Task();
        task.dosya_esas_no= secilenTakip.dosyaNo;
        task.birim_adi=  secilenTakip.birimAdi;
        task.birim_id = secilenTakip?.birimId;
        task.uyap_dosya_acilis_tarihi = secilenTakip.dosyaAcilisTarihi ? new Date(this.$uyapHelper.formatDate(secilenTakip.dosyaAcilisTarihi)).toString() : "";
        task.dosya_id= secilenTakip.dosyaId;
        task.task_type_id= TaskTuru.mts_odeme;
        task.data= this.islemDegerleri;
        tasks.push(task);
      });
      await this.$store.dispatch('postTasks', tasks);
      await this.$router.push(ProgramPaths.eicrapro + '/islemler');
      this.genelForm.activeStep++;
      this.onFly=false;
    }
  }
}
