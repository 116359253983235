
import {Component, Vue} from "vue-property-decorator";
import MtsOdemeFormWizard from "@/components/forms/form-wizard/eicrapro/MtsOdemeFormWizard.vue";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components:{UyapBagliDegilView, MtsOdemeFormWizard}
})
export default class MtsOdemeView extends Vue {
  taskTuru = TaskTuru;
}
